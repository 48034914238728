/** @format */

import React, { useState } from "react";
import styled from "styled-components";
import { Button, Upload, Spin } from "antd";
import type { UploadProps } from "antd";
import ImgCrop from "antd-img-crop";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

import "utils/i18n";
import CssLoading from "components/shared/elements/CssLoading";
import CheckAnimation from "components/shared/elements/CheckAnimation";
import { Toast } from "components/shared/elements/Toast";
import ModelForm from "components/shared/model/ModelForm";
import FormInput from "components/shared/form/InputForm";
import { validateUser } from "constants/validations";
import DefaultAvatar from "components/User/DefaultAvatar";
import { compressImage, dataURLtoFile } from "utils/compressImage";
import Camera from "assets/TopBar/Camera";
import * as api from "api";

const StyledContainer = styled.div`
  background: #f8f8f8;
  width: calc(100% - 70px);
  padding: 40px 25px;
  min-height: calc(100% - 130px);
  position: absolute;
`;

const StyledAvatar = styled.div`
  text-align: center;
  margin-bottom: 24px;
  height: 125px;
`;

const DeleteContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const ResetButton = styled.div`
  color: ${(props: { disabled: boolean }) =>
    props.disabled ? "#cdcdcd;" : "#1890ff"};
  cursor: ${(props: { disabled: boolean }) => !props.disabled && "pointer"};
  :hover {
    color: ${(props: { disabled: boolean }) =>
      props.disabled ? "#cdcdcd;" : "#43a4fd"};
  }
  :focus {
    color: ${(props: { disabled: boolean }) =>
      props.disabled ? "#cdcdcd;" : "#147fdd"};
  }
`;

const StyledTips = styled.div`
  color: #949494;
  font-size: 12px;
`;

const EditOverlay = styled.div`
  height: 66px;
  width: 66px;
  border-radius: 50px;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

interface MyFormValues {
  firstName: string;
  lastName: string;
  email: string;
  avatarFlag: boolean;
}

const CreateModel = ({
  open,
  errors,
  onSubmit,
  onCancel,
  modelFormRef,
  initialValues,
  onNameChanged = () => {},
  loading = false,
}: {
  open: boolean;
  errors?: {};
  loading?: boolean;
  modelFormRef?: any;
  initialValues: MyFormValues;
  onSubmit: (values: any, actions: any) => void;
  onCancel: () => void;
  onNameChanged?: () => void;
}) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const [fileList] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [isUploadSuccess, setUploadSuccess] = useState(false);
  const [isEnableEditAvatar, setIsEnableEditAvatar] = useState(false);

  const onMouseEnter = () => {
    setIsEnableEditAvatar(true);
  };

  const onMouseOut = () => {
    setIsEnableEditAvatar(false);
  };

  const handleModalOk = () => {
    setUploadLoading(true);
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      Toast(
        {
          message: "You can only upload JPG/PNG file!",
        },
        "error"
      );
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      Toast(
        {
          message: "Image must smaller than 1MB!",
        },
        "error"
      );
    }
    return isJpgOrPng && isLt1M;
  };

  const customerUpload = async ({
    file,
    onSuccess,
    onError,
  }: {
    file: any;
    onSuccess: (response: any, file: any) => void;
    onError: (e: any) => void;
  }) => {
    const compressfile = await compressImage(file, 80);
    const base64ToFile = dataURLtoFile(compressfile, "avatar");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "image/jpeg");
    const requestOptions: any = {
      method: "PUT",
      headers: myHeaders,
      body: base64ToFile,
      redirect: "follow",
    };

    try {
      const result = await api.fetchUploadAvatar();
      const url = result.avatarUrl;
      fetch(url, requestOptions)
        .then((response) => response.text())
        .then((response) => {
          onSuccess(response, file);
          queryClient.invalidateQueries("user");
          queryClient.invalidateQueries("userAvatar");
        })
        .catch((error) => {
          onError(() => {
            Toast(
              {
                message: "Upload success!",
              },
              "success"
            );
          });
        });
    } catch (e) {
      Toast(
        {
          message: "Get upload uri failed",
        },
        "error"
      );
      setUploadLoading(false);
    }
  };

  const onUploadSuccess = () => {
    setUploadSuccess(true);
    setUploadLoading(false);
  };

  const onUploadError = () => {
    Toast(
      {
        message: "Upload fail!",
      },
      "error"
    );
    setUploadLoading(false);
  };

  const handleDeleteAvatar = async () => {
    try {
      await api.deleteAvatar();
      Toast(
        {
          message: "Delete successfully!",
        },
        "success"
      );
      queryClient.invalidateQueries("user");
      queryClient.removeQueries("userAvatar");
    } catch (e) {
      Toast(
        {
          message: "Delete failed!",
        },
        "error"
      );
    }
  };

  const uploadNodeProps: any = {
    accept: "image/*",
    method: "PUT",
    fileList: fileList,
    beforeUpload: beforeUpload,
    progress: "line",
    customRequest: customerUpload,
    onSuccess: onUploadSuccess,
    onError: onUploadError,
  };

  const ImgCropProps: any = {
    width: 32,
    height: 32,
    resize: false,
  };
  return (
    <ModelForm
      open={open}
      onSubmit={onSubmit}
      onCancel={onCancel}
      title={t("profileSettings.title")}
      validationSchema={validateUser(errors)}
      initialValues={initialValues}
      ref={modelFormRef}
      closable={true}
      primaryName="Save"
      layout="horizontal"
      destroyOnClose={true}
      loading={loading}
      footerStyle={{ marginTop: "50px" }}
      wrapClassName="profile-setting-model"
    >
      <StyledAvatar>
        <CheckAnimation isStart={isUploadSuccess} setInit={setUploadSuccess} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ImgCrop
            {...ImgCropProps}
            shape="round"
            modalOk="Save"
            modalTitle="Crop your new profile picture"
            modalWidth={500}
            onModalOk={handleModalOk}
            data-auid="profileSettings_form_avatar"
          >
            <Upload
              {...uploadNodeProps}
              data-auid="profileSettings_form__avatar_upload"
            >
              <Spin
                spinning={uploadLoading}
                indicator={<CssLoading text="Loading" height="66px" />}
              >
                <div
                  style={{ width: "66px", position: "relative" }}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseOut}
                >
                  <DefaultAvatar
                    size={{ height: "66px", width: "66px" }}
                    fontSize="25px"
                    enableHover={false}
                    profile={initialValues}
                  />
                  {isEnableEditAvatar && !uploadLoading && (
                    <EditOverlay>
                      <Button
                        type="link"
                        style={{ color: "#fff", padding: "4px 2px" }}
                        data-auid="profileSettings_form_avatar_camera"
                      >
                        <Camera />
                      </Button>
                    </EditOverlay>
                  )}
                </div>
              </Spin>
            </Upload>
          </ImgCrop>
        </div>
        <DeleteContainer>
          <ResetButton
            onClick={() => {
              if (initialValues.avatarFlag) {
                handleDeleteAvatar();
              }
            }}
            data-auid="profileSettings_form__avatar_reset"
            disabled={!initialValues.avatarFlag}
          >
            Reset
          </ResetButton>
        </DeleteContainer>
        <StyledTips>{t("profileSettings.uploadTips")}</StyledTips>
      </StyledAvatar>
      <FormInput
        name="firstName"
        label={t("profileSettings.form.firstName")}
        data-auid="profileSettings_form_firstName"
        required
        maxLength={30}
      />
      <FormInput
        name="lastName"
        label={t("profileSettings.form.lastName")}
        data-auid="profileSettings_form_lastName"
        required
        maxLength={30}
      />
      <FormInput
        name="email"
        label={t("profileSettings.form.email")}
        data-auid="profileSettings_form_email"
        maxLength={30}
        onChange={() => {
          onNameChanged();
        }}
        disabled
      />
    </ModelForm>
  );
};

export default CreateModel;

/** @format */

import en from "locales/en/en.json";
import zh from "locales/zh/zh.json";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";

export const resources = {
  en: {
    translation: en,
  },
  zh: {
    translation: zh,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: "en",
  resources,
});
